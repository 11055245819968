
import { Vue, Component } from 'vue-property-decorator'

@Component({
    name: 'messages-tab-bar',
})
export default class MessagesTabBar extends Vue {
    active = 'online'

    pressed(tab: string): void {
        this.active = tab
    }
}
