
import { Vue, Component } from 'vue-property-decorator'

@Component({
    name: 'LoginLayer',
})
export default class LoginLayer extends Vue {
    get isLoggedIn() {
        return this.$store.auth.store.isLoggedIn || true
    }
    get userEmail() {
        return this.$store.auth.store.user?.email
    }
    pressedLogin() {
        console.log('pressed')
        this.$store.auth.login()
    }
    pressedLogout() {
        this.$store.auth.logout()
    }
}
