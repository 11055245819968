
import { Component, Vue } from 'vue-property-decorator'
import AnimatedInteger from './components/AnimatedInteger.vue'
import MessagesOverview from './components/MessagesOverview.vue'
import LoginLayer from './components/LoginLayer.vue'
import AppHeader from './components/AppHeader.vue'
import EditLayer from './components/EditLayer.vue'
import { Message } from './models/message'
import { ViewMessage } from './models/ViewMessage'

@Component({
    components: {
        AnimatedInteger,
        MessagesOverview,
        AppHeader,
        EditLayer,
        LoginLayer,
    },
})
export default class App extends Vue {
    get isLoggedIn() {
        return this.$store.auth.store.isLoggedIn
    }
    value = 5
    hasLayer: { message: Message } | null = null
    async mounted(): Promise<void> {
        // await sleep(2000)
        // this.value = 1000
        // this.hasLayer = true
        // await sleep(8000)
        // this.hasLayer = false
    }
    async showLayer(message: Message) {
        this.hasLayer = { message }
    }
    async duplicate(message: Message) {
        this.hasLayer = null
        const duplicate = await this.$store.messages.duplicate(message)
        if (duplicate) {
            const view = new ViewMessage(message)
            await view.mapAllLocalizationImageURLs((url) => this.$store.images.duplicate(url))
            this.showLayer(duplicate)
        }
    }
    async archive(message: Message) {
        this.hasLayer = null
        await this.$store.messages.moveMessage(message, 'archived')
    }
    async remove(message: Message) {
        this.hasLayer = null
        console.log('delete start ', message.id)
        const urlsToDelete = new ViewMessage(message).allLocalizationImageURLs
        const deleteImagesPromise = Promise.all(urlsToDelete.map((url) => this.$store.images.delete(url)))
        const removeMessagePromise = this.$store.messages.remove(message)
        await Promise.all([deleteImagesPromise, removeMessagePromise])
        console.log('delete end ', message.id)
    }
}
