
import { sleep } from '@lotum/rocket.js'
import { Vue, Component, Watch } from 'vue-property-decorator'
import AppHeader from './AppHeader.vue'
import MessagesTabBar from './MessagesTabBar.vue'
import TabBar from './TabBar.vue'
import MessageRow from './MessageRow.vue'
import countries from 'i18n-iso-countries'
import { Message } from '@/models/message'

@Component({
    name: 'MessagesOverview',
    components: { AppHeader, MessagesTabBar, MessageRow, TabBar },
})
export default class MessagesOverview extends Vue {
    loading = false

    tabs = ['Active', 'Saved', 'Archive'] as const
    active = 'Active'

    get messages() {
        if (this.active === 'Active') {
            return this.$store.messages.store.active.sortBy('validTo', 'desc')
        } else if (this.active === 'Saved') {
            return this.$store.messages.store.saved.sortBy('validTo', 'desc')
        } else if (this.active === 'Archive') {
            return this.$store.messages.store.archived.sortBy('validTo', 'desc')
        }
        return []
    }
    get textNoMessages() {
        if (this.active === 'Active') {
            return 'There are currently no active messages'
        } else if (this.active === 'Saved') {
            return 'There are currently no saved messages'
        } else if (this.active === 'Archive') {
            return 'There are currently no archived messages'
        }
        return ''
    }

    regions() {
        return countries.getNames('en')
    }
    @Watch('loading') async change(): Promise<void> {
        await sleep(2000)
        this.loading = false
    }
    pressedRow(message: Message) {
        this.$emit('show', message)
    }
    changeTab(tab: string) {
        this.active = tab
    }
    async create() {
        this.loading = true
        const firestoreMessage = Message.create()
        const message = await this.$store.messages.create(firestoreMessage)
        this.loading = false
        this.active = 'Saved'
        if (message) {
            this.pressedRow(message)
        }
    }
}
