import Vue from 'vue'

import App from './App.vue'
import './stores/store'

import 'flag-icon-css/css/flag-icon.min.css'
import '@/styles/styles.css'

Vue.config.productionTip = false

new Vue({
    render: (h) => h(App),
}).$mount('#app')
