import Vue, { PluginFunction } from 'vue'
import { store } from '../dependencies'

declare module 'vue/types/vue' {
    interface Vue {
        $store: typeof store
    }
}

const s: PluginFunction<any> = (vue) => {
    Object.defineProperty(vue.prototype, '$store', {
        enumerable: true,
        configurable: false,
        get() {
            return store
        },
    })
}

Vue.use(s)
