import Vue from 'vue'
import { User } from '../models/user'
import firebase from 'firebase'

export class Auth {
    //reactive
    readonly store: {
        user: User | null
        isLoggedIn: boolean
    }

    constructor(readonly auth: firebase.auth.Auth, readonly provider: firebase.auth.GoogleAuthProvider) {
        this.store = Vue.observable({
            user: null,
            get isLoggedIn() {
                return this.user !== null
            },
        })
        auth.onAuthStateChanged((user) => {
            const allowedUser = user && this.isAllowed(user) ? user : null
            this.store.user = User.fromFirebase(allowedUser)
            if (allowedUser) {
                console.log('USER: ', allowedUser)
                //USER IS LOGGED IN NOW
            }
        })
        auth.getRedirectResult()
            .then((result) => {
                const { user } = result
                if (user) {
                    const allowedUser = user && this.isAllowed(user) ? user : null
                    this.store.user = User.fromFirebase(allowedUser)
                }
            })
            .catch((e) => {
                this.store.user = null
            })
    }

    async login() {
        console.log('LOGIN')
        this.auth
            .signInWithPopup(this.provider)
            .catch(() => this.auth.signInWithRedirect(this.provider))
            .catch(console.error)
    }

    logout() {
        this.auth.signOut()
    }

    private isAllowed(user: firebase.User): boolean {
        return !!(user.email && user.email.match(/lotum\.de$/))
    }
}
