
import { Vue, Component, Prop } from 'vue-property-decorator'
import { LocaInfo } from '../helper/LocaMagic'
import DropTarget from './drop-target.vue'

@Component({
    name: 'DialogLayer',
    components: { DropTarget },
})
export default class DialogLayer extends Vue {
    @Prop() localization!: LocaInfo & { title: string; text: string; cta: string; image?: string }
    private originalImage: string | undefined = undefined
    mounted() {
        if (this.localization.image) {
            const url = this.localization.image
            this.originalImage = url || undefined
            new Promise<HTMLImageElement>((res, rej) => {
                const i = new Image()
                i.onload = () => res(i)
                i.onerror = () => rej()
                i.src = url
            })
                .then((image) => {
                    const info = getImageInfos(image)
                    this.image = { ...info, url }
                })
                .catch(() => console.log(`error loading image ${url}`))
        }
    }
    private state: 'normal' | 'saving' = 'normal'
    private image: { x: number; y: number; size?: number; url: string } | null = null
    get regionCode() {
        return this.localization.regionCode
    }
    get langText() {
        return this.localization.language
    }
    private title = this.localization.title
    private text = this.localization.text
    private cta = this.localization.cta
    get newLocalization() {
        return {
            ...this.localization,
            title: this.title,
            text: this.text,
            cta: this.cta,
            image: this.image?.url,
        }
    }

    async droppedImage(event: { base64: string; file: File; name: string; image: HTMLImageElement | null }) {
        if (!event.image || !event.file.type.match(/image/)) {
            return
        }
        const info = getImageInfos(event.image)
        this.image = { ...info, size: event.file.size, url: require('@/assets/ajax-loader.gif') }
        console.log('Preview done!')
        const { url } = await this.$store.images.upload(event.file, (p) => console.log('progress ', p))
        await new Promise((res) => Object.assign(new Image(), { src: url, onload: res, onerror: res }))
        this.image = { ...info, size: event.file.size, url }
        console.log('URL done!')
    }
    async removeImage() {
        if (this.image && this.image.url) {
            const { url } = this.image
            this.image = null
            await this.$store.images.delete(url)
        }
    }

    pressedAbort() {
        this.$emit('close')
    }
    async pressedSave() {
        this.state = 'saving'
        await this.$nextTick()
        this.$emit('save', this.newLocalization)
    }
}

function getImageInfos(img: HTMLImageElement) {
    var iTime = performance.getEntriesByName(img.src)[0] as { transferSize?: number }
    return { x: img.width, y: img.height, size: iTime && iTime.transferSize }
}
