/* eslint-disable @typescript-eslint/ban-ts-comment */
import Papa from 'papaparse'

const spreadsheetColumnHeaders = [
    'key',
    'comment',
    'status',
    'qa',
    'length',
    'vars',
    'universal',
    'spaces',
    'markup',
    'nbh',
    'nbsp',
    'max-length',
    'de',
    'en',
    'fr',
    'es',
    'it',
    'pt',
    'pl',
    'id',
    'vi',
    'th',
    'tr',
    'ar',
    'nl',
    'ru',
    'ja',
    'hu',
    'el',
    'da',
    'fi',
    'hr',
    'ms',
    'nb',
    'sv',
    'sk',
    'cs',
    'uk',
    'ro',
]

export class PasteTransformer {
    constructor(readonly text: string) {}

    getJson(): {
        title: { [code: string]: string }
        text: { [code: string]: string }
        cta: { [code: string]: string }
    } {
        const rawArray = Papa.parse(this.text).data as any[][]
        console.log(rawArray)
        const locaObject = rawArray.reduce((map, row, idx) => {
            const type = ['title', 'text', 'cta'][idx]
            //@ts-ignore
            map[type] = row.reduce((map, cell, idx) => {
                map[spreadsheetColumnHeaders[idx]] = cell
                return map
            }, {})
            return map
        }, {})
        //@ts-ignore
        return locaObject
    }
}
