
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    name: 'ToggleComponent',
})
export default class ToggleComponent extends Vue {
    @Prop({ default: false }) on!: boolean
    @Prop({ default: 'bg-indigo-600' }) activeClass!: string
    pressed() {
        this.$emit('changed')
    }
}
