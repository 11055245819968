export const games = ['qp', 'wb', '4p'] as const
export const platforms = ['ig', 'native', 'rooms'] as const
export const oss = ['ios', 'android', 'other'] as const

// DB TYPES

type LanguageCode = string
type LocalizedFields = {
    title: string
    text: string
    cta: string
    image?: string
}
type RegionCode = string

/**
 * Firestore document data
 */
export type FirestoreMessage = {
    game: typeof games[number]
    localizations: Record<LanguageCode, LocalizedFields>
    filters: {
        regionFilter?: RegionFilter
        platformFilter?: PlatformFilter
        osFilter?: OSFilter
        testsegmentationFilter?: TestsegmentationFilter
        versionFilters?: VersionFilter[]
        osVersionFilters?: VersionFilter[]
        useLanguageAsFilter?: boolean
    }
    validFrom: number
    validTo: number
    isTimezoneAware: boolean
    trackingID: string
    isDebug?: boolean
    isConsumable?: boolean
    currency?: number
    link?: string
    isAutoOpen?: boolean
}

type RegionFilter = {
    includes: RegionCode[]
    excludes: RegionCode[]
}
type VersionFilter = {
    version: string
    operator: '>' | '>=' | '<' | '<=' | '='
}
type PlatformFilter = {
    includes: typeof platforms[number][]
}
type OSFilter = {
    includes: typeof oss[number][]
}
type TestsegmentationFilter = {
    includes: string[]
}

export class Message {
    constructor(readonly id: string) {}
}
