export default {
    aa: {
        name: 'Afar',
        native: 'Afar',
    },
    ab: {
        name: 'Abkhazian',
        native: 'Аҧсуа',
    },
    af: {
        name: 'Afrikaans',
        native: 'Afrikaans',
    },
    ak: {
        name: 'Akan',
        native: 'Akana',
    },
    am: {
        name: 'Amharic',
        native: 'አማርኛ',
    },
    an: {
        name: 'Aragonese',
        native: 'Aragonés',
    },
    ar: {
        name: 'Arabic',
        native: 'العربية',
        rtl: 1,
    },
    as: {
        name: 'Assamese',
        native: 'অসমীয়া',
    },
    av: {
        name: 'Avar',
        native: 'Авар',
    },
    ay: {
        name: 'Aymara',
        native: 'Aymar',
    },
    az: {
        name: 'Azerbaijani',
        native: 'Azərbaycanca / آذربايجان',
    },
    ba: {
        name: 'Bashkir',
        native: 'Башҡорт',
    },
    be: {
        name: 'Belarusian',
        native: 'Беларуская',
    },
    bg: {
        name: 'Bulgarian',
        native: 'Български',
    },
    bh: {
        name: 'Bihari',
        native: 'भोजपुरी',
    },
    bi: {
        name: 'Bislama',
        native: 'Bislama',
    },
    bm: {
        name: 'Bambara',
        native: 'Bamanankan',
    },
    bn: {
        name: 'Bengali',
        native: 'বাংলা',
    },
    bo: {
        name: 'Tibetan',
        native: 'བོད་ཡིག / Bod skad',
    },
    br: {
        name: 'Breton',
        native: 'Brezhoneg',
    },
    bs: {
        name: 'Bosnian',
        native: 'Bosanski',
    },
    ca: {
        name: 'Catalan',
        native: 'Català',
    },
    ce: {
        name: 'Chechen',
        native: 'Нохчийн',
    },
    ch: {
        name: 'Chamorro',
        native: 'Chamoru',
    },
    co: {
        name: 'Corsican',
        native: 'Corsu',
    },
    cr: {
        name: 'Cree',
        native: 'Nehiyaw',
    },
    cs: {
        name: 'Czech',
        native: 'Čeština',
    },
    cu: {
        name: 'Old Church Slavonic / Old Bulgarian',
        native: 'словѣньскъ / slověnĭskŭ',
    },
    cv: {
        name: 'Chuvash',
        native: 'Чăваш',
    },
    cy: {
        name: 'Welsh',
        native: 'Cymraeg',
    },
    da: {
        name: 'Danish',
        native: 'Dansk',
    },
    de: {
        name: 'German',
        native: 'Deutsch',
    },
    dv: {
        name: 'Divehi',
        native: 'ދިވެހިބަސް',
        rtl: 1,
    },
    dz: {
        name: 'Dzongkha',
        native: 'ཇོང་ཁ',
    },
    ee: {
        name: 'Ewe',
        native: 'Ɛʋɛ',
    },
    el: {
        name: 'Greek',
        native: 'Ελληνικά',
    },
    en: {
        name: 'English',
        native: 'English',
    },
    eo: {
        name: 'Esperanto',
        native: 'Esperanto',
    },
    es: {
        name: 'Spanish',
        native: 'Español',
    },
    et: {
        name: 'Estonian',
        native: 'Eesti',
    },
    eu: {
        name: 'Basque',
        native: 'Euskara',
    },
    fa: {
        name: 'Persian',
        native: 'فارسی',
        rtl: 1,
    },
    ff: {
        name: 'Peul',
        native: 'Fulfulde',
    },
    fi: {
        name: 'Finnish',
        native: 'Suomi',
    },
    fj: {
        name: 'Fijian',
        native: 'Na Vosa Vakaviti',
    },
    fo: {
        name: 'Faroese',
        native: 'Føroyskt',
    },
    fr: {
        name: 'French',
        native: 'Français',
    },
    fy: {
        name: 'West Frisian',
        native: 'Frysk',
    },
    ga: {
        name: 'Irish',
        native: 'Gaeilge',
    },
    gd: {
        name: 'Scottish Gaelic',
        native: 'Gàidhlig',
    },
    gl: {
        name: 'Galician',
        native: 'Galego',
    },
    gn: {
        name: 'Guarani',
        native: "Avañe'ẽ",
    },
    gu: {
        name: 'Gujarati',
        native: 'ગુજરાતી',
    },
    gv: {
        name: 'Manx',
        native: 'Gaelg',
    },
    ha: {
        name: 'Hausa',
        native: 'هَوُسَ',
        rtl: 1,
    },
    he: {
        name: 'Hebrew',
        native: 'עברית',
        rtl: 1,
    },
    hi: {
        name: 'Hindi',
        native: 'हिन्दी',
    },
    ho: {
        name: 'Hiri Motu',
        native: 'Hiri Motu',
    },
    hr: {
        name: 'Croatian',
        native: 'Hrvatski',
    },
    ht: {
        name: 'Haitian',
        native: 'Krèyol ayisyen',
    },
    hu: {
        name: 'Hungarian',
        native: 'Magyar',
    },
    hy: {
        name: 'Armenian',
        native: 'Հայերեն',
    },
    hz: {
        name: 'Herero',
        native: 'Otsiherero',
    },
    ia: {
        name: 'Interlingua',
        native: 'Interlingua',
    },
    id: {
        name: 'Indonesian',
        native: 'Bahasa Indonesia',
    },
    ie: {
        name: 'Interlingue',
        native: 'Interlingue',
    },
    ig: {
        name: 'Igbo',
        native: 'Igbo',
    },
    ii: {
        name: 'Sichuan Yi',
        native: 'ꆇꉙ / 四川彝语',
    },
    ik: {
        name: 'Inupiak',
        native: 'Iñupiak',
    },
    io: {
        name: 'Ido',
        native: 'Ido',
    },
    is: {
        name: 'Icelandic',
        native: 'Íslenska',
    },
    it: {
        name: 'Italian',
        native: 'Italiano',
    },
    iu: {
        name: 'Inuktitut',
        native: 'ᐃᓄᒃᑎᑐᑦ',
    },
    ja: {
        name: 'Japanese',
        native: '日本語',
    },
    jv: {
        name: 'Javanese',
        native: 'Basa Jawa',
    },
    ka: {
        name: 'Georgian',
        native: 'ქართული',
    },
    kg: {
        name: 'Kongo',
        native: 'KiKongo',
    },
    ki: {
        name: 'Kikuyu',
        native: 'Gĩkũyũ',
    },
    kj: {
        name: 'Kuanyama',
        native: 'Kuanyama',
    },
    kk: {
        name: 'Kazakh',
        native: 'Қазақша',
    },
    kl: {
        name: 'Greenlandic',
        native: 'Kalaallisut',
    },
    km: {
        name: 'Cambodian',
        native: 'ភាសាខ្មែរ',
    },
    kn: {
        name: 'Kannada',
        native: 'ಕನ್ನಡ',
    },
    ko: {
        name: 'Korean',
        native: '한국어',
    },
    kr: {
        name: 'Kanuri',
        native: 'Kanuri',
    },
    ks: {
        name: 'Kashmiri',
        native: 'कश्मीरी / كشميري',
        rtl: 1,
    },
    ku: {
        name: 'Kurdish',
        native: 'Kurdî / كوردی',
        rtl: 1,
    },
    kv: {
        name: 'Komi',
        native: 'Коми',
    },
    kw: {
        name: 'Cornish',
        native: 'Kernewek',
    },
    ky: {
        name: 'Kyrgyz',
        native: 'Кыргызча',
    },
    la: {
        name: 'Latin',
        native: 'Latina',
    },
    lb: {
        name: 'Luxembourgish',
        native: 'Lëtzebuergesch',
    },
    lg: {
        name: 'Ganda',
        native: 'Luganda',
    },
    li: {
        name: 'Limburgian',
        native: 'Limburgs',
    },
    ln: {
        name: 'Lingala',
        native: 'Lingála',
    },
    lo: {
        name: 'Laotian',
        native: 'ລາວ / Pha xa lao',
    },
    lt: {
        name: 'Lithuanian',
        native: 'Lietuvių',
    },
    lu: {
        name: 'Luba-Katanga',
        native: 'Tshiluba',
    },
    lv: {
        name: 'Latvian',
        native: 'Latviešu',
    },
    mg: {
        name: 'Malagasy',
        native: 'Malagasy',
    },
    mh: {
        name: 'Marshallese',
        native: 'Kajin Majel / Ebon',
    },
    mi: {
        name: 'Maori',
        native: 'Māori',
    },
    mk: {
        name: 'Macedonian',
        native: 'Македонски',
    },
    ml: {
        name: 'Malayalam',
        native: 'മലയാളം',
    },
    mn: {
        name: 'Mongolian',
        native: 'Монгол',
    },
    mo: {
        name: 'Moldovan',
        native: 'Moldovenească',
    },
    mr: {
        name: 'Marathi',
        native: 'मराठी',
    },
    ms: {
        name: 'Malay',
        native: 'Bahasa Melayu',
    },
    mt: {
        name: 'Maltese',
        native: 'bil-Malti',
    },
    my: {
        name: 'Burmese',
        native: 'မြန်မာစာ',
    },
    na: {
        name: 'Nauruan',
        native: 'Dorerin Naoero',
    },
    nb: {
        name: 'Norwegian Bokmål',
        native: 'Norsk bokmål',
    },
    nd: {
        name: 'North Ndebele',
        native: 'Sindebele',
    },
    ne: {
        name: 'Nepali',
        native: 'नेपाली',
    },
    ng: {
        name: 'Ndonga',
        native: 'Oshiwambo',
    },
    nl: {
        name: 'Dutch',
        native: 'Nederlands',
    },
    nn: {
        name: 'Norwegian Nynorsk',
        native: 'Norsk nynorsk',
    },
    no: {
        name: 'Norwegian',
        native: 'Norsk',
    },
    nr: {
        name: 'South Ndebele',
        native: 'isiNdebele',
    },
    nv: {
        name: 'Navajo',
        native: 'Diné bizaad',
    },
    ny: {
        name: 'Chichewa',
        native: 'Chi-Chewa',
    },
    oc: {
        name: 'Occitan',
        native: 'Occitan',
    },
    oj: {
        name: 'Ojibwa',
        native: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin',
    },
    om: {
        name: 'Oromo',
        native: 'Oromoo',
    },
    or: {
        name: 'Oriya',
        native: 'ଓଡ଼ିଆ',
    },
    os: {
        name: 'Ossetian / Ossetic',
        native: 'Иронау',
    },
    pa: {
        name: 'Panjabi / Punjabi',
        native: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي',
    },
    pi: {
        name: 'Pali',
        native: 'Pāli / पाऴि',
    },
    pl: {
        name: 'Polish',
        native: 'Polski',
    },
    ps: {
        name: 'Pashto',
        native: 'پښتو',
        rtl: 1,
    },
    pt: {
        name: 'Portuguese',
        native: 'Português',
    },
    qu: {
        name: 'Quechua',
        native: 'Runa Simi',
    },
    rm: {
        name: 'Raeto Romance',
        native: 'Rumantsch',
    },
    rn: {
        name: 'Kirundi',
        native: 'Kirundi',
    },
    ro: {
        name: 'Romanian',
        native: 'Română',
    },
    ru: {
        name: 'Russian',
        native: 'Русский',
    },
    rw: {
        name: 'Rwandi',
        native: 'Kinyarwandi',
    },
    sa: {
        name: 'Sanskrit',
        native: 'संस्कृतम्',
    },
    sc: {
        name: 'Sardinian',
        native: 'Sardu',
    },
    sd: {
        name: 'Sindhi',
        native: 'सिनधि',
    },
    se: {
        name: 'Northern Sami',
        native: 'Sámegiella',
    },
    sg: {
        name: 'Sango',
        native: 'Sängö',
    },
    sh: {
        name: 'Serbo-Croatian',
        native: 'Srpskohrvatski / Српскохрватски',
    },
    si: {
        name: 'Sinhalese',
        native: 'සිංහල',
    },
    sk: {
        name: 'Slovak',
        native: 'Slovenčina',
    },
    sl: {
        name: 'Slovenian',
        native: 'Slovenščina',
    },
    sm: {
        name: 'Samoan',
        native: 'Gagana Samoa',
    },
    sn: {
        name: 'Shona',
        native: 'chiShona',
    },
    so: {
        name: 'Somalia',
        native: 'Soomaaliga',
    },
    sq: {
        name: 'Albanian',
        native: 'Shqip',
    },
    sr: {
        name: 'Serbian',
        native: 'Српски',
    },
    ss: {
        name: 'Swati',
        native: 'SiSwati',
    },
    st: {
        name: 'Southern Sotho',
        native: 'Sesotho',
    },
    su: {
        name: 'Sundanese',
        native: 'Basa Sunda',
    },
    sv: {
        name: 'Swedish',
        native: 'Svenska',
    },
    sw: {
        name: 'Swahili',
        native: 'Kiswahili',
    },
    ta: {
        name: 'Tamil',
        native: 'தமிழ்',
    },
    te: {
        name: 'Telugu',
        native: 'తెలుగు',
    },
    tg: {
        name: 'Tajik',
        native: 'Тоҷикӣ',
    },
    th: {
        name: 'Thai',
        native: 'ไทย / Phasa Thai',
    },
    ti: {
        name: 'Tigrinya',
        native: 'ትግርኛ',
    },
    tk: {
        name: 'Turkmen',
        native: 'Туркмен / تركمن',
    },
    tl: {
        name: 'Tagalog / Filipino',
        native: 'Tagalog',
    },
    tn: {
        name: 'Tswana',
        native: 'Setswana',
    },
    to: {
        name: 'Tonga',
        native: 'Lea Faka-Tonga',
    },
    tr: {
        name: 'Turkish',
        native: 'Türkçe',
    },
    ts: {
        name: 'Tsonga',
        native: 'Xitsonga',
    },
    tt: {
        name: 'Tatar',
        native: 'Tatarça',
    },
    tw: {
        name: 'Twi',
        native: 'Twi',
    },
    ty: {
        name: 'Tahitian',
        native: 'Reo Mā`ohi',
    },
    ug: {
        name: 'Uyghur',
        native: 'Uyƣurqə / ئۇيغۇرچە',
    },
    uk: {
        name: 'Ukrainian',
        native: 'Українська',
    },
    ur: {
        name: 'Urdu',
        native: 'اردو',
        rtl: 1,
    },
    uz: {
        name: 'Uzbek',
        native: 'Ўзбек',
    },
    ve: {
        name: 'Venda',
        native: 'Tshivenḓa',
    },
    vi: {
        name: 'Vietnamese',
        native: 'Tiếng Việt',
    },
    vo: {
        name: 'Volapük',
        native: 'Volapük',
    },
    wa: {
        name: 'Walloon',
        native: 'Walon',
    },
    wo: {
        name: 'Wolof',
        native: 'Wollof',
    },
    xh: {
        name: 'Xhosa',
        native: 'isiXhosa',
    },
    yi: {
        name: 'Yiddish',
        native: 'ייִדיש',
        rtl: 1,
    },
    yo: {
        name: 'Yoruba',
        native: 'Yorùbá',
    },
    za: {
        name: 'Zhuang',
        native: 'Cuengh / Tôô / 壮语',
    },
    zh: {
        name: 'Chinese',
        native: '中文',
    },
    zu: {
        name: 'Zulu',
        native: 'isiZulu',
    },
}
