
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const types = ['image/png', 'image/gif', 'image/bmp', 'image/jpg']

@Component({
    name: 'drop-target',
    components: {},
})
export default class DropTarget extends Vue {
    private isOver = false
    over() {
        // console.log('over')
        this.isOver = true
    }
    leave() {
        // console.log('leave')
        this.isOver = false
    }
    async drop(evt: DragEvent) {
        this.isOver = false
        const file = evt.dataTransfer && evt.dataTransfer.files[0]
        if (!file) {
            return
        }
        if (!types.includes(file.type)) {
            console.warn(file.name, ' is no image ')
        }
        const info = await this.makeInfo(file)
        console.log('Dropped ', info)
        this.$emit('dropped', info)
    }

    private async makeInfo(
        file: File
    ): Promise<{ base64: string; file: File; name: string; image: HTMLImageElement | null }> {
        return new Promise((res, rej) => {
            const reader = new FileReader()
            reader.onload = async (evt: ProgressEvent) => {
                const src = evt.target && evt.target instanceof FileReader && evt.target.result
                const name = file.name
                const loadedImage: HTMLImageElement | null = await (() => {
                    return new Promise<HTMLImageElement | null>((res, rej) => {
                        if (typeof src === 'string') {
                            const i = new Image()
                            i.src = src
                            i.onload = () => res(i)
                            i.onerror = rej
                        } else {
                            res(null)
                        }
                    })
                })()
                if (typeof src === 'string') {
                    res({
                        base64: src,
                        file,
                        name,
                        image: loadedImage,
                    })
                } else {
                    rej()
                }
            }
            reader.readAsDataURL(file)
        })
    }
}
