
import { Vue, Component, Prop } from 'vue-property-decorator'
import EditGamesTab from '@/components/EditGamesTab.vue'
import EditTextImageTab from '@/components/EditTextImageTab.vue'
import EditRegionTab from '@/components/EditRegionTab.vue'
import EditPublishTab from '@/components/EditPublishTab.vue'
import EditButtonTab from '@/components/EditButtonTab.vue'
import EditFilterTab from '@/components/EditFilterTab.vue'
import MessageRow from '@/components/MessageRow.vue'
import EditQuickActionMenu from '@/components/EditQuickActionMenu.vue'
import TabBar from '@/components/TabBar.vue'
import ToggleComponent from '@/components/toggle-component.vue'

import { Message } from '@/models/message'
import { ViewMessage } from '@/models/ViewMessage'

@Component({
    name: 'EditLayer',
    components: {
        EditGamesTab,
        EditTextImageTab,
        TabBar,
        ToggleComponent,
        EditQuickActionMenu,
        EditRegionTab,
        EditPublishTab,
        EditButtonTab,
        EditFilterTab,
        MessageRow,
    },
})
export default class EditLayer extends Vue {
    @Prop() message!: Message
    get msg() {
        return new ViewMessage(this.message)
    }

    //TAB HANDLING
    tabs = ['Game', 'Text & Image', 'Button & Currency', 'Region', 'Filter', 'Publish']
    currentTab = 'Game'
    changeTab(tab: string) {
        this.currentTab = tab
    }

    //GAME TAB HANDLING
    get game() {
        return this.msg.message.game
    }
    gameChanged(game: 'qp' | '4p' | 'wb'): void {
        this.message.updateWith({ game })
    }

    //GAME STATE active OR preview OR saved
    get state(): 'active' | 'preview' | 'saved' | undefined {
        return this.msg.isDebug
            ? 'preview'
            : this.message.category === 'active'
            ? 'active'
            : this.message.category === 'saved'
            ? 'saved'
            : undefined
    }
    async changeState(to: 'active' | 'preview' | 'saved') {
        const moveTo = to == 'saved' ? 'saved' : 'active'
        if (to === 'active') {
            const shouldPublish = confirm('Are you sure you want to activate (publish) this message for all users?')
            if (!shouldPublish) {
                return
            }
        }
        this.$store.messages.moveMessage(this.message, moveTo)
        if (to == 'preview') {
            this.message.updateWith({ isDebug: true })
        } else {
            this.message.updateWith({ isDebug: false })
        }
    }

    //MENU ACTIONS AND STATE
    isMenuOpen = false
    menuAction(action?: 'duplicate' | 'archive' | 'delete') {
        if (!action) {
            this.isMenuOpen = false
        } else {
            this.isMenuOpen = !this.isMenuOpen
        }
        if (action == 'duplicate') {
            this.$emit('duplicate', this.message)
        } else if (action == 'archive') {
            this.$emit('archive', this.message)
        } else if (action == 'delete') {
            this.$emit('remove', this.message)
        }
    }

    close() {
        console.log('CLOSE')
        this.$emit('close')
    }
}
