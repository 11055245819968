import Vue from 'vue'
import firebase from 'firebase'
import { Message } from '@/models/message'
import { CollectionRepository } from '@/helper/CollectionObserver'
import { FirestoreMessage } from '@/models/shared-types'
import { eventQueue } from '@/dependencies'
import { sleep } from '@lotum/rocket.js'

type Store = {
    active: Message[]
    saved: Message[]
    archived: Message[]
}
type Category = 'active' | 'saved' | 'archived'

export class Messages {
    //reactive
    readonly store: Store

    constructor(readonly firestore: firebase.firestore.Firestore) {
        this.store = Vue.observable({
            active: [],
            saved: [],
            archived: [],
        })
        //ACTIVE MESSAGES
        const collectionName = this.getCollectionNameForCategory('active')
        const activeObserver = new CollectionRepository(collectionName, firestore)
        activeObserver.emitter.on('added', (id, json) =>
            this.store.active.push(
                new Message(id, (json as unknown) as FirestoreMessage, { deliveries: 0, scores: 0 }, 'active')
            )
        )
        activeObserver.emitter.on('updated', (id, json) =>
            this.store.active.find((msg) => msg.id == id)?.updateWith(json, true)
        )
        activeObserver.emitter.on('removed', (id) => {
            console.log('removed message ', id)
            const index = this.store.active.findIndex((msg) => msg.id == id)
            index > -1 && this.store.active.splice(index, 1)
        })
        activeObserver.start().then(() => {
            //COUNTER
            const activeCounter = new CollectionRepository('counter', firestore)
            activeCounter.emitter.on('added', (id, { deliveries = 0, scores = 0 }) =>
                this.updateCountFor(id, { deliveries: Number(deliveries), scores: Number(scores) })
            )
            activeCounter.emitter.on('updated', (id, { deliveries = 0, scores = 0 }) =>
                this.updateCountFor(id, { deliveries: Number(deliveries), scores: Number(scores) })
            )
            activeCounter.start()
        })
        //SAVED MESSAGES
        const savedObserver = new CollectionRepository(this.getCollectionNameForCategory('saved'), firestore)
        savedObserver.emitter.on('added', (id, json) =>
            this.store.saved.push(
                new Message(id, (json as unknown) as FirestoreMessage, { deliveries: 0, scores: 0 }, 'saved')
            )
        )
        savedObserver.emitter.on('updated', (id, json) =>
            this.store.saved.find((msg) => msg.id == id)?.updateWith(json, true)
        )
        savedObserver.emitter.on('removed', (id) => {
            const index = this.store.saved.findIndex((msg) => msg.id == id)
            index > -1 && this.store.saved.splice(index, 1)
        })
        savedObserver.start()
        //ARCHIVED MESSAGES
        const archivedObserver = new CollectionRepository(this.getCollectionNameForCategory('archived'), firestore)
        archivedObserver.emitter.on('added', (id, json) =>
            this.store.archived.push(
                new Message(id, (json as unknown) as FirestoreMessage, { deliveries: 0, scores: 0 }, 'archived')
            )
        )
        archivedObserver.emitter.on('updated', (id, json) =>
            this.store.archived.find((msg) => msg.id == id)?.updateWith(json, true)
        )
        archivedObserver.emitter.on('removed', (id) => {
            const index = this.store.archived.findIndex((msg) => msg.id == id)
            index > -1 && this.store.archived.splice(index, 1)
        })
        archivedObserver.start()

        // save changes to messages with a little delay
        const runningDelayedChanges = new Map<string, null | NodeJS.Timeout>()
        eventQueue.on('messageChanged', (message) => {
            const runningTimer = runningDelayedChanges.get(message.id)
            runningTimer && clearTimeout(runningTimer)
            const newTimer = setTimeout(() => {
                const collectionName = this.getCollectionNameForCategory(message.category)
                if (!collectionName) {
                    return
                }
                runningDelayedChanges.set(message.id, null)
                firestore.collection(collectionName).doc(message.id).set(message.message)
            }, 1000)
            runningDelayedChanges.set(message.id, newTimer)
        })
    }

    async moveMessage(fromMessage: Message, toCategory: Category) {
        const oldCategory = fromMessage.category
        if (oldCategory == toCategory) {
            return
        }
        const oldCollection = this.getCollectionNameForCategory(fromMessage.category)
        const oldDocument = this.firestore.collection(oldCollection).doc(fromMessage.id)
        const newCollection = this.getCollectionNameForCategory(toCategory)
        const newDocument = this.firestore.collection(newCollection).doc(fromMessage.id)
        await newDocument.set(fromMessage.message).then(() => oldDocument.delete())
        fromMessage.category = toCategory
    }

    async duplicate(fromMessage: Message) {
        const collectionName = this.getCollectionNameForCategory('saved') // duplicates are always created in 'saved'
        const { id } = await this.firestore.collection(collectionName).add(fromMessage.message)
        await sleep(500)
        return this.store.saved.find((m) => m.id === id)
    }
    async remove(fromMessage: Message) {
        const collectionName = this.getCollectionNameForCategory(fromMessage.category)
        await this.firestore.collection(collectionName).doc(fromMessage.id).delete()
    }
    async create(message: FirestoreMessage) {
        const collectionName = this.getCollectionNameForCategory('saved')
        const { id } = await this.firestore.collection(collectionName).add(message)
        await sleep(500)
        return this.store.saved.find((m) => m.id === id)
    }

    private updateCountFor(id: string, counter: { deliveries: number; scores: number }) {
        this.store.active.find((m) => m.id == id)?.updateCount(counter)
        this.store.saved.find((m) => m.id == id)?.updateCount(counter)
        this.store.archived.find((m) => m.id == id)?.updateCount(counter)
    }
    private getCollectionNameForCategory(category: Category): string {
        if (category === 'active') return 'messages'
        if (category === 'saved') return 'saved'
        if (category === 'archived') return 'archive'
        throw new Error('unknown category' + category)
    }
}
