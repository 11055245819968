
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { animate, circOut } from 'popmotion'

@Component({
    name: 'animated-integer',
})
export default class AnimatedInteger extends Vue {
    @Prop() number!: number
    @Prop({ default: 250 }) duration!: number

    private stop: (() => void) | null = null
    private rawDisplay = this.number
    get displayNumber() {
        return this.rawDisplay.toLocaleString()
    }

    @Watch('number') didChange(newValue: number): void {
        this.stop?.()
        this.stop = animate({
            from: this.rawDisplay,
            to: newValue,
            duration: this.duration,
            ease: circOut,
            onUpdate: (value: number) => {
                this.rawDisplay = Math.round(value)
            },
        }).stop
    }
}
