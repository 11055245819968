import { FirestoreMessage } from './shared-types'
import Vue from 'vue'
import { eventQueue } from '@/dependencies'

export class Message {
    static create(): FirestoreMessage {
        return {
            game: 'qp',
            filters: {},
            isTimezoneAware: true,
            localizations: {},
            trackingID: '',
            validFrom: Date.now(),
            validTo: Date.now() + 24 * 60 * 60 * 1000,
            currency: 10,
            isAutoOpen: false,
        }
    }
    constructor(
        readonly id: string,
        public message: FirestoreMessage,
        public counters: { deliveries: number; scores: number },
        public category: 'active' | 'saved' | 'archived'
    ) {
        !message.filters && (message.filters = {})
    }

    get game() {
        return this.message.game
    }
    get localizations() {
        return this.message.localizations ?? {}
    }
    get validFrom() {
        return this.message.validFrom
    }
    get validTo() {
        return this.message.validTo
    }
    get isTimezoneAware() {
        return this.message.isTimezoneAware
    }
    get isDebug() {
        return this.message.isDebug
    }
    get isConsumable() {
        return this.message.isConsumable
    }
    get isAutoOpen() {
        return this.message.isAutoOpen
    }
    get currency() {
        return this.message.currency
    }
    get link() {
        return this.message.link
    }
    get trackingID() {
        return this.message.trackingID
    }

    get filters() {
        return this.message.filters
    }
    addFilter(filter: FirestoreMessage['filters']) {
        this.message.filters = Object.assign({}, this.message.filters, Vue.observable(filter))
        eventQueue.emit('messageChanged', this)
    }

    updateWith(json: Partial<FirestoreMessage>, preventWriting = false) {
        console.log('Update message ', json, this.id)
        const newMessage = Object.assign({}, this.message, json)
        this.message = newMessage //we need to reset it for reactivity
        if (!preventWriting) {
            eventQueue.emit('messageChanged', this)
        }
    }
    updateCount(newCount: { deliveries: number; scores: number }) {
        this.counters = newCount
    }
}
