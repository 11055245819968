
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    name: 'EditGamesTab',
})
export default class EditGamesTab extends Vue {
    @Prop({ required: true }) selected!: 'qp' | '4p' | 'wb'

    pressed(game: 'qp' | '4p' | 'wb'): void {
        this.$emit('change', game)
    }
}
