export const charactersArray = [...'abcdefghijklmnpqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890']
export const charactersSet = new Set(charactersArray)

/**
 * Creates a generator to walk through a range of numbers like [1,2,3,4,5]
 * Example [...range(1,5)] == [1,2,3,4,5]
 * @param from Starting number included
 * @param to End number included if step lands on it
 * @param steps Number to add in each iteration
 */
export function* range(from: number, to: number = Number.MAX_VALUE, steps = 1) {
    for (let i = from; i <= to; i = i + steps) {
        yield i
    }
}

const DECIMAL_ROUNDING = 10 ** 2 //round£ to 2 decimal numbers

export function round(value: number, decimal_rounding = DECIMAL_ROUNDING) {
    return Math.round(value * decimal_rounding) / decimal_rounding
}

export function generateRandomID(length = 10) {
    const size = charactersArray.length
    return [...range(1, length)].map(() => charactersArray[Math.floor(Math.random() * size)]).join('')
}

export function JSONP(text: string | null): Record<string, unknown> | any[] | null {
    if (!text) {
        return null
    }
    try {
        return JSON.parse(text)
    } catch {
        return null
    }
}
