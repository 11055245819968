
import { Vue, Component } from 'vue-property-decorator'

@Component({
    name: 'app-header',
})
export default class AppHeader extends Vue {
    get isLoggedIn() {
        return this.$store.auth.store.isLoggedIn
    }
    get avatar() {
        return this.$store.auth.store.user?.photoURL
    }
    pressedLogout() {
        this.$store.auth.logout()
    }
}
