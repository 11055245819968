
import { Message } from '@/models/message'
import { ViewMessage } from '@/models/ViewMessage'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AnimatedInteger from './AnimatedInteger.vue'
import FilterRegion from './FilterRegion.vue'
import FilterLanguage from './FilterLanguage.vue'
import FilterRow from './FilterRow.vue'

@Component({
    name: 'MessageRow',
    components: { AnimatedInteger, FilterRegion, FilterLanguage, FilterRow },
})
export default class MessageRow extends Vue {
    @Prop() message!: Message
    get msg(): ViewMessage {
        return new ViewMessage(this.message)
    }
    get icon() {
        return this.msg.gamePhotoURL
    }
    get title() {
        return this.msg.previewTitle
    }
    get languages() {
        return this.msg.localizations.map(({ langText }) => langText)
    }
    get regionFilter() {
        return this.msg.regionFilter
    }
    get stateBadge() {
        if (this.msg.state === 'live') {
            const valid = 'Till ' + this.msg.humanReadableValidTo
            return {
                badge: ['bg-green-100', 'text-green-800'],
                dot: ['text-green-400'],
                text: 'Live',
                valid,
                counter: this.msg.message.counters,
            }
        }
        if (this.msg.state === 'scheduled') {
            const valid = 'From ' + this.msg.humanReadableValidFrom
            return { badge: ['bg-orange-100', 'text-orange-800'], dot: ['text-orange-400'], text: 'Scheduled', valid }
        }
        if (this.msg.state === 'expired') {
            const valid = 'At ' + this.msg.humanReadableValidTo
            return {
                badge: ['bg-cool-gray-100', 'text-cool-gray-800'],
                dot: ['text-cool-gray-400'],
                text: 'Expired',
                valid,
                counter: this.msg.message.counters,
            }
        }
        if (this.msg.state === 'error') {
            const valid = 'From ' + this.msg.humanReadableValidFrom
            const title = this.msg.isValid.state === 'error' ? this.msg.isValid.message : ''
            return { badge: ['bg-red-100', 'text-red-800'], dot: ['text-red-400'], text: 'Error', valid, title }
        }
        if (this.msg.state === 'preview') {
            const valid = 'From ' + this.msg.humanReadableValidToFrom
            return {
                badge: ['bg-indigo-100', 'text-indigo-800'],
                dot: ['text-indigo-400'],
                text: 'Preview',
                valid,
                counter: this.msg.message.counters,
            }
        }
        if (this.msg.state === 'saved' || this.msg.state === 'archived') {
            const valid = 'From ' + this.msg.humanReadableValidToFrom
            return {
                badge: ['bg-cool-gray-100', 'text-cool-gray-800'],
                dot: ['text-cool-gray-400'],
                text: this.msg.state === 'saved' ? 'Saved' : 'Archived',
                valid,
                counter: this.msg.message.counters,
            }
        }
        return undefined
    }
}
