
import { LocaMagic } from '@/helper/LocaMagic'
import { Message } from '@/models/message'
import { ViewMessage } from '@/models/ViewMessage'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ToggleComponent from './toggle-component.vue'
import FilterRegion from './FilterRegion.vue'

const filterDefault = { includes: [], excludes: [] }

@Component({
    name: 'EditRegionTab',
    components: { ToggleComponent, FilterRegion },
})
export default class EditRegionTab extends Vue {
    @Prop() message!: Message
    get msg() {
        return new ViewMessage(this.message)
    }
    allRegions = LocaMagic.getAllCountries()

    filterIncludes = ''
    filterExcludes = ''

    get regionFilter() {
        return this.msg.regionFilter
    }

    get included() {
        return this.allRegions
            .map(({ code, name }) => ({
                code,
                name,
                on: this.regionFilter?.includes.includes(code) ?? false,
            }))
            .filter(({ name }) => name.match(new RegExp(this.filterIncludes, 'i')))
    }
    get textIncluded() {
        const items = this.regionFilter?.includes
            .map((code) => this.allRegions.find((r) => r.code == code)?.name)
            .sort()
        return items && items.length > 0 ? items.join(', ') : 'Everything'
    }
    toggleInclude(code: string) {
        let includes = this.regionFilter?.includes ?? []
        if (includes.includes(code)) {
            includes = includes.filter((c) => c != code)
        } else {
            includes.push(code)
        }
        this.message.addFilter({ regionFilter: Object.assign({}, filterDefault, this.regionFilter, { includes }) })
    }

    get excluded() {
        return this.allRegions
            .map(({ code, name }) => ({
                code,
                name,
                on: this.regionFilter?.excludes.includes(code) ?? false,
            }))
            .filter(({ name }) => name.match(new RegExp(this.filterExcludes, 'i')))
    }
    get textExcluded() {
        const items = this.regionFilter?.excludes
            .map((code) => this.allRegions.find((r) => r.code == code)?.name)
            .sort()
        return items && items.length > 0 ? items.join(', ') : 'Nothing'
    }
    toggleExclude(code: string) {
        let excludes = this.regionFilter?.excludes ?? []
        if (excludes.includes(code)) {
            excludes = excludes.filter((c) => c != code)
        } else {
            excludes.push(code)
        }
        this.message.addFilter({ regionFilter: Object.assign({}, filterDefault, this.regionFilter, { excludes }) })
    }

    selectAll(filter: 'excludes' | 'includes') {
        const all = this.allRegions
            .filter((r) =>
                r.name.match(new RegExp(filter == 'excludes' ? this.filterExcludes : this.filterIncludes, 'i'))
            )
            .map(({ code }) => code)
        const current = this.regionFilter?.[filter] ?? []
        const selected = [...new Set(current.concat(all))]
        this.message.addFilter({
            regionFilter: Object.assign({}, filterDefault, this.regionFilter, { [filter]: selected }),
        })
    }
    unselectAll(filter: 'excludes' | 'includes') {
        const all = this.allRegions
            .filter((r) =>
                r.name.match(new RegExp(filter == 'excludes' ? this.filterExcludes : this.filterIncludes, 'i'))
            )
            .map(({ code }) => code)
        const current = this.regionFilter?.[filter] ?? []
        const unselected = current.filter((code) => !all.includes(code))
        this.message.addFilter({
            regionFilter: Object.assign({}, filterDefault, this.regionFilter, { [filter]: unselected }),
        })
    }
    invertAll(filter: 'excludes' | 'includes') {
        const all = this.allRegions
            .filter((r) =>
                r.name.match(new RegExp(filter == 'excludes' ? this.filterExcludes : this.filterIncludes, 'i'))
            )
            .map(({ code }) => code)
        const current = this.regionFilter?.[filter] ?? []
        const inverted = all.reduce((inv, code) => {
            if (inv.includes(code)) {
                inv.splice(inv.indexOf(code), 1)
            } else {
                inv.push(code)
            }
            return inv
        }, current)
        this.message.addFilter({
            regionFilter: Object.assign({}, filterDefault, this.regionFilter, { [filter]: inverted }),
        })
    }
}
