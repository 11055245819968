var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"name":"layer","appear":"","duration":{ enter: 500, leave: 500 }}},[_c('div',{staticClass:"edit-layer",on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_c('div',{staticClass:"layer-wrapper"},[_c('div',{staticClass:"layer w-full p-8 bg-gray-100 shadow-lg rounded-2xl overflow-auto flex flex-col",on:{"click":function($event){$event.stopPropagation();return _vm.menuAction()}}},[_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"text-3xl leading-9 font-medium text-gray-800"},[_vm._v("Edit message")]),_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"relative z-0 inline-flex shadow-sm rounded-md"},[_c('button',{staticClass:"relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150",class:_vm.state == 'active'
                                        ? ['bg-green-500', 'text-white', 'pointer-events-none']
                                        : ['bg-white', 'text-gray-700', 'hover:text-gray-500'],attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.changeState('active')}}},[_vm._v(" Active ")]),_c('button',{staticClass:"-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150",class:_vm.state == 'preview'
                                        ? ['bg-indigo-500', 'text-white', 'pointer-events-none']
                                        : ['bg-white', 'text-gray-700', 'hover:text-gray-500'],attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.changeState('preview')}}},[_c('svg',{staticClass:"mr-2 h-5 w-5",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","fill":"currentColor"}},[_c('path',{attrs:{"d":"M10 12a2 2 0 100-4 2 2 0 000 4z"}}),_c('path',{attrs:{"fill-rule":"evenodd","d":"M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z","clip-rule":"evenodd"}})]),_vm._v(" Preview ")]),_c('button',{staticClass:"-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 text-sm leading-5 font-medium focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150",class:_vm.state == 'saved'
                                        ? ['bg-cool-gray-500', 'text-white', 'pointer-events-none']
                                        : ['bg-white', 'text-gray-700', 'hover:text-gray-500'],attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.changeState('saved')}}},[_vm._v(" Saved ")])]),_c('EditQuickActionMenu',{staticClass:"ml-4",attrs:{"open":_vm.isMenuOpen},on:{"changed":_vm.menuAction}})],1)]),_c('MessageRow',{staticClass:"bg-white mt-6 rounded-2xl shadow-inner hover:bg-white",attrs:{"message":_vm.message}}),_c('div',{staticClass:"pt-8 text-gray-700 mb-5 border-b border-gray-400 space-x-4"},[_c('TabBar',{attrs:{"tabs":_vm.tabs,"active":_vm.currentTab},on:{"changed":_vm.changeTab}})],1),_c('div',{staticClass:"tab-container overscroll-contain overflow-auto"},[(_vm.currentTab === 'Game')?_c('EditGamesTab',{attrs:{"selected":_vm.game},on:{"change":_vm.gameChanged}}):_vm._e(),(_vm.currentTab === 'Text & Image')?_c('EditTextImageTab',{attrs:{"message":_vm.message}}):_vm._e(),(_vm.currentTab === 'Button & Currency')?_c('EditButtonTab',{attrs:{"message":_vm.message}}):_vm._e(),(_vm.currentTab === 'Region')?_c('EditRegionTab',{attrs:{"message":_vm.message}}):_vm._e(),(_vm.currentTab === 'Filter')?_c('EditFilterTab',{attrs:{"message":_vm.message}}):_vm._e(),(_vm.currentTab === 'Publish')?_c('EditPublishTab',{attrs:{"message":_vm.message}}):_vm._e()],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }