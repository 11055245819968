var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"tab-bar"},[_c('div',{staticClass:"hidden sm:block"},[_c('nav',{staticClass:"-mb-px flex space-x-8"},_vm._l((_vm.tabs),function(tab){return _c('a',{key:tab,staticClass:"whitespace-no-wrap pb-4 px-1 border-b-2 border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300",class:tab == _vm.active
                        ? [
                              'border-indigo-500',
                              'text-indigo-600',
                              'focus:outline-none',
                              'focus:text-indigo-800',
                              'focus:border-indigo-700',
                          ]
                        : [],attrs:{"href":"#"},on:{"click":function($event){return _vm.pressed(tab)}}},[_vm._v(" "+_vm._s(tab)+" ")])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }