
import { Message } from '@/models/message'
import { ViewMessage } from '@/models/ViewMessage'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
    name: 'EditButtonTab',
    components: {},
})
export default class EditButtonTab extends Vue {
    @Prop() message!: Message
    get msg() {
        return new ViewMessage(this.message)
    }
    buttonType: 'currency' | 'onlyText' | 'link' = 'currency'
    currency = this.message.currency || null
    isConsumable = this.message.isConsumable || false
    link = this.message.link || ''

    setButtonType(type: 'currency' | 'onlyText' | 'link') {
        this.buttonType = type
    }

    mounted() {
        const buttonType = this.message.currency ? 'currency' : this.message.link ? 'link' : 'onlyText'
        this.buttonType = buttonType
    }

    @Watch('currency')
    currencyChanged() {
        console.log('track chan')
        this.currency = Number(this.currency) || null
    }

    @Watch('buttonType')
    buttonTypeChange() {
        if (this.buttonType === 'onlyText' || this.buttonType === 'currency') {
            this.link = ''
        }
        if (this.buttonType === 'onlyText' || this.buttonType === 'link') {
            this.currency = null
        }
        if (this.buttonType === 'onlyText') {
            this.isConsumable = true
        }
        if (this.buttonType === 'currency' || this.buttonType === 'link') {
            this.isConsumable = false
        }
    }

    @Watch('isConsumable')
    @Watch('buttonType')
    @Watch('currency')
    @Watch('link')
    save() {
        console.log('save now')
        const currency = this.buttonType === 'currency' ? this.currency ?? undefined : undefined
        const isConsumable = currency
            ? undefined //dont set consumable flag if currency is present, currency is always consumable
            : this.buttonType === 'link' && this.link
            ? undefined //dont set consumable flag if link is present, link is always consumable
            : this.buttonType === 'onlyText' && this.isConsumable
            ? true
            : undefined
        const link = this.link || undefined
        this.message.updateWith({
            currency,
            isConsumable,
            link,
        })
    }
}
