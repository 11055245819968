export class Subject<T> {
    readonly promise: Promise<T>

    private _resolve: ((value?: T | PromiseLike<T>) => void) | undefined
    private _reject: ((reason: any) => void) | undefined
    private isResolved = false
    private isRejected = false
    private get isClosed(): boolean {
        return this.isResolved || this.isRejected
    }

    constructor() {
        this.promise = new Promise((res, rej) => {
            this._resolve = res
            this._reject = rej
        })
    }

    resolve(value?: T | PromiseLike<T>) {
        if (this.isClosed) {
            return
        }
        this.isResolved = true
        this._resolve!(value)
    }

    reject(reason?: any) {
        if (this.isClosed) {
            return
        }
        this.isRejected = true
        this._reject!(reason)
    }
}
