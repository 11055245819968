import Vue from 'vue'

type Store = {
    nowMillis: number
    nowSeconds: number
}

export class Tick {
    readonly store: Store = Vue.observable({
        nowMillis: 0,
        get nowSeconds() {
            return Math.floor(this.nowMillis / 1000)
        },
    })

    private timer: any

    constructor() {
        this.start()
    }

    start() {
        this.timer = setInterval(() => {
            this.store.nowMillis = Date.now()
        }, 950)
    }
    stop() {
        clearInterval(this.timer)
    }
}
