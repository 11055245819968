
import { Message } from '@/models/message'
import { ViewMessage } from '@/models/ViewMessage'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
    name: 'EditPublishTab',
    components: {},
})
export default class EditPublishTab extends Vue {
    @Prop() message!: Message
    get msg() {
        return new ViewMessage(this.message)
    }

    mounted() {
        const validFrom = new Date(this.message.validFrom)
        this.from = {
            day: String(validFrom.getUTCDate()),
            month: String(validFrom.getUTCMonth() + 1),
            year: String(validFrom.getUTCFullYear()),
            hour: String(validFrom.getUTCHours()),
            minute: String(validFrom.getUTCMinutes()).padStart(2, '0'),
        }
        console.log('validFrom: ', validFrom, this.message.validFrom, this.from)
        const validTo = new Date(this.message.validTo)
        this.to = {
            day: String(validTo.getUTCDate()),
            month: String(validTo.getUTCMonth() + 1),
            year: String(validTo.getUTCFullYear()),
            hour: String(validTo.getUTCHours()),
            minute: String(validTo.getUTCMinutes()).padStart(2, '0'),
        }
    }

    isAutoOpen = this.message.isAutoOpen ?? false
    isTimezoneAware = this.message.isTimezoneAware
    trackingID = this.message.trackingID || ''

    from = {
        day: '',
        month: '',
        year: '',
        hour: '',
        minute: '',
    }
    to = {
        day: '',
        month: '',
        year: '',
        hour: '',
        minute: '',
    }

    get fromDateString() {
        return this.dateStringFromDate(this.fromDateComponents(this.from))
    }
    get toDateString() {
        return this.dateStringFromDate(this.fromDateComponents(this.to))
    }

    dateStringFromDate(date: Date) {
        return this.msg.makeHumanReadable(date.getTime(), true)
    }

    fromDateComponents({
        year,
        month,
        day,
        hour,
        minute,
    }: {
        day: string
        month: string
        year: string
        hour: string
        minute: string
    }) {
        return new Date(Date.UTC(Number(year), Number(month) - 1, Number(day), Number(hour), Number(minute)))
    }

    @Watch('trackingID')
    trackingChanged() {
        this.trackingID = this.trackingID.trim().replace(/\s/, '-')
    }

    @Watch('isTimezoneAware')
    @Watch('trackingID')
    @Watch('isAutoOpen')
    @Watch('from.day')
    @Watch('from.month')
    @Watch('from.year')
    @Watch('from.hour')
    @Watch('from.minute')
    @Watch('to.day')
    @Watch('to.month')
    @Watch('to.year')
    @Watch('to.hour')
    @Watch('to.minute')
    save() {
        console.log('save now')
        const isAutoOpen = this.isAutoOpen || undefined
        const trackingID = this.trackingID
        const isTimezoneAware = this.isTimezoneAware
        const validFromDate = this.fromDateComponents(this.from)
        const validFrom = validFromDate.getTime()
        const validToDate = this.fromDateComponents(this.to)
        const validTo = validToDate.getTime()
        this.message.updateWith({
            isAutoOpen,
            trackingID,
            isTimezoneAware,
            validFrom,
            validTo,
        })
    }
}
