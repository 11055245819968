export class User {
    static fromFirebase(user: firebase.User | null): User | null {
        if (!user || !user.displayName || !user.email) {
            return null
        }
        return new User(user.uid, user.displayName, user.email, user.photoURL)
    }

    constructor(readonly id: string, readonly name: string, readonly email: string, readonly photoURL: string | null) {}
}
