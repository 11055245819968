import { Subject } from './Subject'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { EventEmitter } from '@lotum/rocket.js'
import { Json } from '@/models/types'

/**
 * This repository is a in-memory representation of a firestore collection
 */
export class CollectionRepository {
    readonly emitter = new EventEmitter({
        added: (id: string, doc: Json) => console.log(id, doc),
        updated: (id: string, doc: Json) => console.log(id, doc),
        removed: (id: string) => console.log(id),
    })
    private listeningState: { state: 'off' } | { state: 'on'; unregister: () => void; loadedSubject: Subject<void> } = {
        state: 'off',
    }
    private documents = new Map<string, Json>()
    get isInitialized() {
        return this.listeningState.state === 'on' ? this.listeningState.loadedSubject.promise : Promise.resolve()
    }

    constructor(readonly collectionName: string, private firestore: firebase.firestore.Firestore) {}

    /**
     * Returns all documents in this collection
     * Documents are references so be careful modifying them
     * @returns [documentID, documentData][]
     */
    getAllDocuments(): [string, Json][] {
        return [...this.documents.entries()]
    }

    /**
     * Returns all documents matching the given predicate
     * Documents are references so be careful modifying them
     * @returns [documentID, documentData][]
     */
    filterDocumentsWithPredicate(predicate: (data: Json) => boolean): [string, Json][] {
        const resultArray: [string, Json][] = []
        for (const [id, doc] of this.documents) {
            if (predicate(doc)) {
                resultArray.push([id, doc])
            }
        }
        return resultArray
    }

    /**
     * Returns the first matching document in its collection
     * @returns [documentID, documentData][] | undefined
     */
    findDocumentWithPredicate(predicate: (data: [string, Json]) => boolean): [string, Json] | undefined {
        for (const [id, doc] of this.documents) {
            if (predicate([id, doc])) {
                return [id, doc]
            }
        }
    }

    /**
     * Start listening to collection updates
     * Initially fill the local cache of documents
     * Returns a promise which resolves as soon as the collection data is fully loaded
     */
    start(): Promise<void> {
        if (this.listeningState.state === 'on') {
            return this.listeningState.loadedSubject.promise
        }
        const loadedSubject = new Subject<void>()
        let initiallyLoaded = false
        const unregister = this.firestore.collection(this.collectionName).onSnapshot((shot) => {
            if (!initiallyLoaded) {
                // first time we add all documents
                // we assume that current documents are empty
                shot.docs.forEach((doc) => this.addDocument(doc.id, doc.data()))
                initiallyLoaded = true
                loadedSubject.resolve()
            } else {
                //further updates are incremental
                shot.docChanges().forEach((change) => {
                    const doc = change.doc
                    if (change.type === 'added') {
                        this.addDocument(doc.id, doc.data())
                    } else if (change.type === 'modified') {
                        this.updateDocument(doc.id, doc.data())
                    } else if (change.type === 'removed') {
                        this.removeDocument(doc.id)
                    }
                })
            }
        })
        this.listeningState = { state: 'on', unregister, loadedSubject }
        return loadedSubject.promise
    }

    /**
     * Stop listening to further collection update
     * To restart call start again
     */
    stop(): void {
        if (this.listeningState.state === 'on') {
            this.listeningState.unregister()
            this.clearDocuments()
        }
        this.listeningState = { state: 'off' }
    }

    private addDocument(id: string, data: Json) {
        this.documents.set(id, data)
        this.emitter.emit('added', id, data)
    }
    private updateDocument(id: string, data: Json) {
        this.documents.set(id, data)
        this.emitter.emit('updated', id, data)
    }
    private removeDocument(id: string) {
        this.documents.delete(id)
        this.emitter.emit('removed', id)
    }
    private clearDocuments() {
        const ids = [...this.documents.keys()]
        this.documents.clear()
        ids.forEach((id) => this.emitter.emit('removed', id))
    }
}
