
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    name: 'FilterLanguage',
    components: {},
})
export default class FilterLanguage extends Vue {
    @Prop() languages!: string[]

    get displayNumber() {
        if (this.languages.length == 0) {
            return 'No languages'
        }
        return String(this.languages.length)
    }

    get languagesString() {
        const all = this.languages
        return all.length > 1 ? all.slice(0, all.length - 1).join(', ') + ' & ' + all[all.length - 1] : all[0]
    }
}
