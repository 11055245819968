
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    name: 'TabBar',
})
export default class TabBar extends Vue {
    @Prop() tabs!: string[]
    @Prop() active!: string

    pressed(tab: string) {
        this.$emit('changed', tab)
    }
}
