
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
    name: 'EditQuickActionMenu',
})
export default class EditQuickActionMenu extends Vue {
    @Prop({ default: false }) open!: boolean
    pressed(action?: 'delete' | 'archive' | 'duplicate') {
        this.$emit('changed', action)
    }
}
