import { generateRandomID } from '@/helper/random'
import firebase from 'firebase'

const folder = '/images'

export class Images {
    private imageFolder: firebase.storage.Reference
    constructor(readonly storage: firebase.storage.Storage) {
        this.imageFolder = storage.ref(folder)
    }

    async upload(file: File, progressFn: (progress: number) => void): Promise<{ url: string }> {
        const ext = file.type.match(/png/)
            ? 'png'
            : file.type.match(/jpe?g/)
            ? 'jpg'
            : file.type.match(/svg/)
            ? 'svg'
            : file.type.match(/gif/)
            ? 'gif'
            : 'jpg'
        const fileName = generateRandomID() + '.' + ext
        const fileRef = this.imageFolder.child(fileName)
        const uploadTask = fileRef.put(file, { contentType: file.type })
        return new Promise((res, rej) => {
            uploadTask.on(
                firebase.storage.TaskEvent.STATE_CHANGED,
                ({ bytesTransferred, totalBytes }) => progressFn((bytesTransferred / totalBytes) * 100),
                (error) => rej(error),
                () =>
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                        res({ url: downloadURL })
                    })
            )
        })
    }

    /**
     * Copies a storage file to a new random generated ID file name
     * Copy is not supported out of the box by Firebase Storage -.-
     */
    async duplicate(url: string): Promise<string> {
        const oldRef = this.storage.refFromURL(url)
        const { contentType } = (await oldRef.getMetadata()) ?? {}
        const ext = url.match(/\.(\w{3})(?=\?)/)?.[1] ?? 'jpg'
        const fileName = generateRandomID() + '.' + ext
        const blob = await fetch(url).then((response) => response.blob())
        const fileRef = this.imageFolder.child(fileName)
        const uploadTask = fileRef.put(blob, { contentType })
        return new Promise((res) => {
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, null, null, () =>
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    res(downloadURL)
                })
            )
        })
    }

    async delete(url: string): Promise<void> {
        const ref = this.storage.refFromURL(url)
        return ref.delete()
    }
}
