import countries from 'i18n-iso-countries'
import countriesEN from 'i18n-iso-countries/langs/en.json'
countries.registerLocale(countriesEN)

import languages from '@/helper/languages'

export type LocaInfo = {
    languageCode: string
    language: string
    regionCode: string
    region: string
}

const languageToCountry = {
    ar: 'ar', //argentina
    cs: 'cz', //czech
    da: 'dk',
    de: 'de',
    el: 'gr',
    en: 'us',
    es: 'es',
    fi: 'fi',
    fr: 'fr',
    hr: 'hr',
    hu: 'hu',
    id: 'id',
    it: 'it',
    ja: 'jp',
    ms: 'ms',
    nb: 'no',
    nl: 'nl',
    pl: 'pl',
    pt: 'pt',
    ro: 'ro',
    ru: 'ru',
    sk: 'sk',
    sv: 'se',
    th: 'th',
    tr: 'tr',
    uk: 'ua',
    vi: 'vi',
} as Record<string, string>

export const supportedLanguages = new Set(Object.keys(languageToCountry))

export class LocaMagic {
    static getInfoForLanguage(code: string): LocaInfo {
        const regionCode = languageToCountry[code] ?? code.toLowerCase()
        return {
            languageCode: code.toLowerCase(),
            language: (languages as Record<string, { name: string }>)[code]?.name ?? code,
            regionCode,
            region: countries.getName(regionCode, 'en'),
        }
    }
    static getAll(): LocaInfo[] {
        return Object.keys(languageToCountry).map(LocaMagic.getInfoForLanguage)
    }
    static getAllCountries() {
        const nameMap = countries.getNames('en')
        return Object.keys(nameMap)
            .map((code) => ({
                code: code.toLowerCase(),
                name: (Array.isArray(nameMap[code]) ? nameMap[code][0] : nameMap[code]) as string,
            }))
            .sort((a, b) => (a.name < b.name ? -1 : 1))
    }
}
