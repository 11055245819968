
import { Message } from '@/models/message'
import { ViewMessage } from '@/models/ViewMessage'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { showAllFilterOption } from '@/dependencies'

@Component({
    name: 'FilterRow',
    components: {},
})
export default class FilterRow extends Vue {
    @Prop() message!: Message
    get msg() {
        return new ViewMessage(this.message)
    }
    get showEmpty() {
        return showAllFilterOption
    }
    get platforms() {
        return this.message.filters?.platformFilter?.includes || []
    }
    get oss() {
        return this.message.filters?.osFilter?.includes || []
    }
    get version() {
        return this.message.filters?.versionFilters?.[0] || { version: '', operator: '=' }
    }
    get os_version() {
        return this.message.filters?.osVersionFilters?.[0] || { version: '', operator: '=' }
    }
    get currency() {
        return this.message.currency
    }
    get testgroup() {
        return (this.message.filters?.testsegmentationFilter?.includes || []).join(',') ?? ''
    }
}
