import { Auth } from './stores/auth'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import { Messages } from './stores/messages'
import { Images } from './stores/images'
import { Tick } from './stores/tick'
import { EventEmitter } from '@lotum/rocket.js'
import { Message } from './models/message'

if (!firebase.apps.length) {
    const firebaseConfig = {
        apiKey: 'AIzaSyA7Xe2xdKcLMVT34xXNKK8Jmb-K4UPeM_U',
        authDomain: 'ingamenews.firebaseapp.com',
        databaseURL: 'https://ingamenews.firebaseio.com',
        projectId: 'ingamenews',
        storageBucket: 'ingamenews.appspot.com',
        messagingSenderId: '292549808714',
        appId: '1:292549808714:web:a2560b3c2337042040ccb3',
        ignoreUndefinedProperties: true,
    }
    firebase.initializeApp(firebaseConfig)
    firebase.firestore().settings({
        ignoreUndefinedProperties: true,
    })
}

export const eventQueue = new EventEmitter({ messageChanged: (message: Message) => console.log(message) })

export const auth = new Auth(firebase.auth(), new firebase.auth.GoogleAuthProvider())
export const messages = new Messages(firebase.firestore())
export const images = new Images(firebase.storage())
export const tick = new Tick()

export const store = {
    auth,
    messages,
    images,
    tick,
}

export const showAllFilterOption = false
