
import { LocaMagic } from '@/helper/LocaMagic'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { showAllFilterOption } from '@/dependencies'

const allCountries = LocaMagic.getAllCountries()

@Component({
    name: 'FilterRegion',
    components: {},
})
export default class FilterRegion extends Vue {
    @Prop() regionFilter: { includes: string[]; excludes: string[] } | undefined

    get showEmpty() {
        return showAllFilterOption
    }

    allRegions = LocaMagic.getAllCountries()

    get isEverything() {
        return (
            !this.regionFilter ||
            this.regionFilter?.includes.length === allCountries.length ||
            (!this.hasIncludes && !this.hasExcludes)
        )
    }

    get hasIncludes() {
        return this.includeFlags.length > 0
    }
    get hasExcludes() {
        return this.excludeFlags.length > 0
    }
    get includeFlags() {
        return this.regionFilter?.includes ?? []
    }
    get includeNames() {
        const all = this.includeFlags.map((regionCode) => this.allRegions.find((e) => e.code == regionCode)?.name)
        return all.length > 1 ? all.slice(0, all.length - 1).join(', ') + ' & ' + all[all.length - 1] : all[0]
    }
    get excludeFlags() {
        return this.regionFilter?.excludes ?? []
    }
    get excludeNames() {
        const all = this.excludeFlags.map((regionCode) => this.allRegions.find((e) => e.code == regionCode)?.name)
        return all.length > 1 ? all.slice(0, all.length - 1).join(', ') + ' & ' + all[all.length - 1] : all[0]
    }
}
