
import { Vue, Component, Prop } from 'vue-property-decorator'
import DialogLayer from '@/components/DialogLayer.vue'
import ToggleComponent from '@/components/toggle-component.vue'
import { PasteTransformer } from '@/helper/PasteTransformer'
import { LocaInfo, LocaMagic, supportedLanguages } from "@/helper/LocaMagic";
import { Message } from '@/models/message'
import { ViewMessage } from '@/models/ViewMessage'

@Component({
    name: 'EditTextImageTab',
    components: { DialogLayer, ToggleComponent },
})
export default class EditTextImageTab extends Vue {
    @Prop() message!: Message
    get msg() {
        return new ViewMessage(this.message)
    }

    locaPaste = ''
    dialogLocalization: (LocaInfo & { title: string; text: string; cta: string; image?: string }) | null = null

    allLocalizations = LocaMagic.getAll()
    get addLocalizationButtons(): LocaInfo[] {
        return this.allLocalizations
            .sort((a, b) => (a.language < b.language ? -1 : 0))
            .filter(({ languageCode }) => {
                return !this.localizations.find((l) => l.lang == languageCode)
            })
    }

    get localizations() {
        return this.msg.localizations
    }

    get useLanguageAsFilter() {
        return this.message.filters.useLanguageAsFilter
    }
    toggleLanguageAsFilter() {
        const useLanguageAsFilter = !this.useLanguageAsFilter
        this.message.addFilter({ useLanguageAsFilter })
        console.log(this.message.filters)
    }
    // localizations: {
    //     regionCode: string
    //     lang: string
    //     langText: string
    //     title: string
    //     text: string
    //     cta: string
    //     image: string
    // }[] = []

    get reorderedLocalizations() {
        return this.localizations
            .sort((a) => {
                if (a.lang == 'en') return -1
                return 0
            })
            .map((l) => ({ ...l, isDefault: l.lang == 'en' || this.localizations.length == 1 }))
    }

    locaChanged(event: InputEvent): void {
        if (event.inputType === 'insertFromPaste') {
            try {
                const locaArray = this.generateLanguagesFrom(this.locaPaste)
                const locaMap = locaArray.reduce((map, loca) => {
                    map[loca.lang] = {
                        title: loca.title,
                        text: loca.text,
                        cta: loca.cta,
                    }
                    return map
                }, {} as { [lang: string]: { title: string; text: string; cta: string } })
                this.message.updateWith({ localizations: locaMap })
            } catch (e) {
                console.log('Es ist ein Fehler aufgetreten', e)
            }
            this.locaPaste = ''
        }
        console.log(this.localizations)
    }
    removeLang(code: string): void {
        const localization = this.localizations.find((l) => l.lang == code)
        if (localization) {
            const imageURL = localization.image
            imageURL && this.$store.images.delete(imageURL)
            const oldLoca = this.message.localizations
            const { [code]: _, ...localizations } = oldLoca
            this.message.updateWith({ localizations })
        }
    }
    editLang(code: string): void {
        const localization = this.localizations.find((l) => l.lang == code)
        if (!localization) {
            return
        }
        const loca = LocaMagic.getInfoForLanguage(localization.lang)
        this.dialogLocalization = { ...loca, ...localization } ?? null
    }
    addLang(code: string): void {
        const loca = LocaMagic.getInfoForLanguage(code)
        const localization = {
            lang: code,
            regionCode: loca?.regionCode || code,
            title: '',
            text: '',
            cta: '',
            langText: loca?.language || code,
            image: '',
        }
        const oldLoca = this.message.localizations
        const localizations = { ...oldLoca, [code]: localization }
        this.message.updateWith({ localizations })
    }
    saveLocalization(localization: NonNullable<EditTextImageTab['dialogLocalization']>) {
        console.log('loca', localization)
        this.dialogLocalization = null
        const localizations = this.message.localizations
        localizations[localization.languageCode] = {
            title: localization.title,
            text: localization.text,
            cta: localization.cta,
            image: localization.image,
        }
        this.message.updateWith({ localizations })
    }
    private generateLanguagesFrom(text: string) {
        const json = new PasteTransformer(text).getJson()
        const columnNames = Object.keys(json.title)
        let languageColumns = columnNames
          .sort()
          .filter((column) => supportedLanguages.has(column));
        const result = languageColumns
            .map((lang: string) => {
                const loca = LocaMagic.getInfoForLanguage(lang)
                return {
                    lang,
                    regionCode: loca?.regionCode || lang,
                    title: json.title[lang],
                    text: json.text[lang],
                    cta: json.cta[lang],
                    langText: loca?.language || lang,
                    image: '',
                }
            })
            .filter(({ title, text, cta }) => title && text && cta)

        return result
    }
}
