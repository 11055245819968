
import { Message } from '@/models/message'
import { oss, platforms } from '@/models/shared-types'
import { ViewMessage } from '@/models/ViewMessage'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

@Component({
    name: 'EditFilterTab',
    components: {},
})
export default class EditFilterTab extends Vue {
    @Prop() message!: Message
    get msg() {
        return new ViewMessage(this.message)
    }

    platforms = this.message.filters?.platformFilter?.includes || []
    oss = this.message.filters?.osFilter?.includes || []
    version = (this.message.filters?.versionFilters || [])[0] ?? { version: '', operator: '=' }
    os_version = (this.message.filters?.osVersionFilters || [])[0] ?? { version: '', operator: '=' }
    testgroup = (this.message.filters?.testsegmentationFilter?.includes || []).join(',') ?? ''

    @Watch('version.version')
    versionDidChange() {
        this.version.version = this.version.version.trim().replace(/[^\d.]/gi, '')
    }

    @Watch('os_version.version')
    osversionDidChange() {
        this.os_version.version = this.os_version.version.trim().replace(/[^\d.]/gi, '')
    }

    @Watch('testgroup')
    testgroupDidChange() {
        this.testgroup = this.testgroup.trim().replace(/\s/, '-')
    }

    @Watch('platforms')
    @Watch('oss')
    @Watch('version.version')
    @Watch('version.operator')
    @Watch('os_version.version')
    @Watch('os_version.operator')
    @Watch('testgroup')
    save() {
        const platformFilter =
            this.platforms.length > 0 && this.platforms.length !== platforms.length
                ? { includes: this.platforms }
                : undefined
        const osFilter = this.oss.length > 0 && this.oss.length !== oss.length ? { includes: this.oss } : undefined
        const versionFilters = this.version.version.length > 0 ? [this.version] : undefined
        const osVersionFilters = this.os_version.version.length > 0 ? [this.os_version] : undefined
        const testsegmentationFilter = this.testgroup.length > 0 ? { includes: this.testgroup.split(',') } : undefined
        this.message.addFilter({ platformFilter, osFilter, versionFilters, osVersionFilters, testsegmentationFilter })
        console.log('plat', this.platforms)
    }
}
