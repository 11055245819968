import { tick } from '@/dependencies'
import { LocaMagic } from '@/helper/LocaMagic'
import { Message } from './message'
import { games } from './shared-types'

export class ViewMessage {
    constructor(readonly message: Message) {}

    get isDebug() {
        return this.message.isDebug
    }

    get state(): 'scheduled' | 'live' | 'expired' | 'error' | 'preview' | 'saved' | 'archived' | undefined {
        if (this.message.isDebug) {
            return 'preview'
        }
        if (this.isValid.state === 'error') {
            return 'error'
        }
        if (this.message.category === 'saved') {
            return 'saved'
        }
        if (this.message.category === 'archived') {
            return 'archived'
        }
        if (this.timezoneFrom <= tick.store.nowMillis && this.timezoneTo >= tick.store.nowMillis) {
            return 'live'
        }
        if (this.timezoneFrom > tick.store.nowMillis && tick.store.nowMillis < this.timezoneFrom) {
            return 'scheduled'
        }
        if (this.timezoneFrom < tick.store.nowMillis) {
            return 'expired'
        }
        return undefined
    }

    makeHumanReadable(timestamp: number, includeTime: boolean): string {
        const date = new Date(timestamp)
        const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getUTCDay()]
        return (
            weekday +
            ', ' +
            ('0' + date.getUTCDate()).substr(-2) +
            '.' +
            ('0' + (date.getUTCMonth() + 1)).substr(-2) +
            '.' +
            date.getUTCFullYear() +
            (includeTime
                ? ' · ' + ('0' + date.getUTCHours()).substr(-2) + ':' + ('0' + date.getUTCMinutes()).substr(-2) + ' Uhr'
                : '')
        )
    }
    makeHumanReadableShort(timestamp: number, includeTime: boolean): string {
        const date = new Date(timestamp)
        const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][
            date.getUTCMonth()
        ]
        return (
            month +
            ' ' +
            date.getUTCDate() +
            ', ' +
            date.getUTCFullYear() +
            (includeTime
                ? ' · ' + ('0' + date.getUTCHours()).substr(-2) + ':' + ('0' + date.getUTCMinutes()).substr(-2) + ' Uhr'
                : '')
        )
    }

    get humanReadableValidTo() {
        return this.makeHumanReadable(this.message.validTo, true)
    }
    get humanReadableValidFrom() {
        return this.makeHumanReadable(this.message.validFrom, true)
    }
    get humanReadableValidToFrom() {
        return (
            this.makeHumanReadableShort(this.message.validFrom, true) +
            ' - ' +
            this.makeHumanReadableShort(this.message.validTo, true)
        )
    }

    get timezoneTo() {
        //max is UTC+14
        return this.message.validTo + (this.isTimezoneAware ? 14 * 60 * 60 * 1000 : 0)
    }
    get timezoneFrom() {
        //max is UTC-12
        return this.message.validFrom - (this.isTimezoneAware ? 12 * 60 * 60 * 1000 : 0)
    }
    get isTimezoneAware() {
        return this.message.isTimezoneAware
    }

    get localizations() {
        const languages = Object.keys(this.message.localizations)
        const result = languages
            .sort()
            .filter((lang) => lang.length == 2)
            .map((lang: string) => {
                const loca = LocaMagic.getInfoForLanguage(lang)
                return {
                    lang,
                    regionCode: loca?.regionCode || lang,
                    title: this.message.localizations[lang].title,
                    text: this.message.localizations[lang].text,
                    cta: this.message.localizations[lang].cta,
                    langText: loca?.language || lang,
                    image: this.message.localizations[lang].image,
                    isDefault: lang == 'en' || languages.length == 1,
                }
            })
            .sort((a) => {
                if (a.lang == 'en') return -1
                return 0
            })

        return result
    }

    get previewTitle() {
        return this.localizations[0]?.title ?? 'No title yet'
    }

    get gamePhotoURL(): string {
        if (this.message.game === 'qp') {
            return require('@/assets/qp-small2.jpg')
        } else if (this.message.game === 'wb') {
            return require('@/assets/wordblitz.jpg')
        } else if (this.message.game === '4p') {
            return require('@/assets/4p.png')
        }
        return ''
    }

    get allLocalizationImageURLs() {
        return Object.keys(this.message.localizations)
            .map((code) => this.message.localizations[code].image)
            .filter((url) => url) as string[]
    }

    async mapAllLocalizationImageURLs(fn: (url: string) => Promise<string>) {
        await Promise.all(
            Object.keys(this.message.localizations)
                .filter((code) => this.message.localizations[code].image)
                .map(
                    async (code) =>
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        (this.message.localizations[code].image = await fn(this.message.localizations[code].image!))
                )
        )
    }

    get regionFilter() {
        return this.message.filters.regionFilter
    }

    get isValid(): { state: 'ok' } | { state: 'error'; message: string } {
        const gameOK = this.message.game && typeof this.message.game == 'string' && games.includes(this.message.game)
        if (!gameOK) {
            return {
                state: 'error',
                message: `Game is ${this.message.game} but has to be one of ${JSON.stringify(games)}`,
            }
        }
        const localizationOK =
            this.message.localizations &&
            Object.keys(this.message.localizations).length > 0 &&
            Object.keys(this.message.localizations).every(
                (lang) =>
                    this.message.localizations[lang].text &&
                    this.message.localizations[lang].title &&
                    this.message.localizations[lang].cta
            )
        if (!localizationOK) {
            return { state: 'error', message: 'Localizations are empty or either text, title or cta is missing in one' }
        }
        const validFromOK = this.message.validFrom && typeof this.message.validFrom == 'number'
        if (!validFromOK) {
            return { state: 'error', message: 'validFrom has to be a number' }
        }
        const validToOK = this.message.validTo && typeof this.message.validTo == 'number'
        if (!validToOK) {
            return { state: 'error', message: 'validTo has to be a number' }
        }
        const timezoneawareOK = typeof this.message.isTimezoneAware == 'boolean'
        if (!timezoneawareOK) {
            return { state: 'error', message: 'isTimezoneAware has to be a boolean' }
        }

        const debugOK = this.message.isDebug === undefined || typeof this.message.isDebug == 'boolean'
        if (!debugOK) {
            return { state: 'error', message: 'isDebug has to be a boolean' }
        }
        const consumableOK = this.message.isConsumable === undefined || typeof this.message.isConsumable == 'boolean'
        if (!consumableOK) {
            return { state: 'error', message: 'isConsumable has to be a boolean' }
        }
        const currencyOK = this.message.currency === undefined || typeof this.message.currency == 'number'
        if (!currencyOK) {
            return { state: 'error', message: 'currency has to be a number' }
        }
        const linkOK = this.message.link === undefined || typeof this.message.link == 'string'
        if (!linkOK) {
            return { state: 'error', message: 'link has to be a number' }
        }
        const autoopenOK = this.message.isAutoOpen === undefined || typeof this.message.isAutoOpen == 'boolean'
        if (!autoopenOK) {
            return { state: 'error', message: 'isAutoOpen has to be a number' }
        }

        return { state: 'ok' }
    }
}
